import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_country = _resolveComponent("tm-country")!
  const _component_tm_person = _resolveComponent("tm-person")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, {
    headers: _ctx.headers,
    items: _ctx.items
  }, {
    "body-cell-number-slot": _withCtx((props) => [
      _createVNode(_component_tm_country, {
        country: {
          id: props.row.number.country.id,
          name: props.row.number.phone,
        },
        size: "xSmall"
      }, null, 8, ["country"])
    ]),
    "body-cell-account-slot": _withCtx((props) => [
      _createVNode(_component_tm_person, {
        name: props.row.account.fullName,
        "avatar-color": props.row.account.avatarColor,
        "avatar-size": "small"
      }, null, 8, ["name", "avatar-color"])
    ]),
    _: 1
  }, 8, ["headers", "items"]))
}