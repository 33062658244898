
import { defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import NumbersTable from '@/components/pages/reporting/numbers/NumbersTable.vue'
import ReportingFilter from '@/components/pages/reporting/ReportingFilter.vue'
import { getTableData } from '@/services/tableService'
import type{ TableHeaders } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    ReportingFilter,
    NumbersTable,
    PageContent,
  },
  setup() {
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Textmagic number', value: 'number-slot' },
      { text: 'Account', value: 'account-slot' },
      { text: 'Sent SMS', value: 'sentSms', width: '10%' },
      { text: 'Received SMS', value: 'receivedSms', width: '10%' },
      { text: 'Calls forwarded', value: 'calls', width: '10%' },
    ])
    const tableItems = ref(getTableData('reportingNumbers'))

    return {
      tableHeaders,
      tableItems,
    }
  },
})
